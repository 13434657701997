// src/components/Header/Header.jsx

import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import MobileMenu from './MobileMenu';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-white shadow">
      <div className="container mx-auto max-w-screen-xl px-6 py-4 flex justify-between items-center">
        <div className="text-2xl font-bold text-indigo-600">Fiscus</div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6 items-center">
          <a href="#benefits" className="hover:text-indigo-600">Benefits</a>
          <a href="#features" className="hover:text-indigo-600">Features</a>
          {/* <a href="#pricing" className="hover:text-indigo-600">Pricing</a> */}
          <a href="https://docs.fiscusflows.com/" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-600">Developers</a>
          <a
            href="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
            className="bg-indigo-600 text-white px-5 py-2 rounded-full hover:bg-indigo-700 transition"
          >
            Get Started
          </a>
        </nav>

        {/* Mobile Hamburger Menu */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-gray-800 hover:text-indigo-600 focus:outline-none">
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && <MobileMenu toggleMenu={toggleMobileMenu} />}
    </header>
  );
};

export default Header;
