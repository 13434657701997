// src/components/BenefitsSection.jsx

import React from 'react';
import { motion } from 'framer-motion';
import { FiClock, FiShield, FiLayers, FiCode, FiZap, FiTrendingUp } from 'react-icons/fi';

const benefits = [
	{
	  title: "Instant Integration, Zero Hassle",
	  description: "Skip the long setup times. Fiscus connects and configures your integrations within minutes, guiding you through a single chat-driven setup that requires no technical know-how.",
	  icon: <FiClock className="text-indigo-600" />,
	},
	{
	  title: "Effortless Security and Compliance",
	  description: "We handle authentication, compliance, and data security across integrations, ensuring your workflows are airtight and aligned with best practices—without adding work for your team.",
	  icon: <FiShield className="text-blue-500" />,
	},
	{
	  title: "Dynamic, AI-Managed Workflows",
	  description: "Our AI adapts to every use case, dynamically managing multi-step workflows, error handling, and decision logic. Automate the complex without lifting a finger.",
	  icon: <FiLayers className="text-purple-600" />,
	},
	{
	  title: "Unmatched Flexibility with SDK Customization",
	  description: "Need deep customization? Our SDK lets technical users enhance and fine-tune workflows, supercharging API integrations without managing backend complexities.",
	  icon: <FiCode className="text-teal-500" />,
	},
	{
	  title: "Guaranteed Accuracy and Reliability",
	  description: "Eliminate inconsistencies across platforms with Fiscus’s intelligent checks and real-time monitoring, ensuring data flows seamlessly and accurately at all times.",
	  icon: <FiZap className="text-green-500" />,
	},
	{
	  title: "Built for Scale and Growth",
	  description: "From startups to enterprise, Fiscus scales effortlessly with your needs. Unlock limitless integrations, complex logic, and reliable performance—no matter the volume.",
	  icon: <FiTrendingUp className="text-yellow-500" />,
	},
  ];  

const BenefitsSection = () => {
	return (
	  <section id="benefits" className="bg-gradient-to-b from-gray-50 to-gray-100 py-20">
		<div className="container mx-auto max-w-screen-lg px-6">
		  <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-12">
		  	Endless Integrations. Zero Engineering.
		  </h2>
		  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
			{benefits.map((benefit, index) => (
			  <motion.div
				key={index}
				className="relative flex items-start pl-4 pr-4 p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
				initial={{ opacity: 0, y: 10 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ delay: index * 0.1 }}
			  >
				<div className="mr-4 text-xl">{benefit.icon}</div>
				<div className="flex-1">
				  <h3 className="text-lg font-semibold text-gray-800 mb-1">{benefit.title}</h3>
				  <p className="text-sm text-gray-600 leading-relaxed">{benefit.description}</p>
				</div>
			  </motion.div>
			))}
		  </div>
		</div>
	  </section>
	);
  };
  
  export default BenefitsSection;
  