import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const CallToAction = () => {
  return (
    <section className="bg-indigo-600">
      <div className="container mx-auto max-w-screen-xl px-6 py-20 text-center">
        
        {/* Stronger Title */}
        <h2 className="text-3xl font-bold text-white mb-6">
          Step Into the Future of Seamless Integrations
        </h2>
        
        {/* Refined Subtext */}
        <p className="text-lg text-indigo-200 mb-8">
          Automate, scale, and simplify complex workflows without a single line of code. Fiscus is ready—are you?
        </p>
        
        {/* CTA Button */}
        <a
          href="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
          className="inline-flex items-center bg-white text-indigo-600 px-6 py-3 rounded-lg hover:bg-gray-100 transition text-lg font-semibold"
        >
          Start for Free Now <FaArrowRight className="ml-2" />
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
