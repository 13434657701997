// src/components/Header/MobileMenu.jsx

import React from 'react';

const MobileMenu = ({ toggleMenu }) => {
  return (
    <nav className="bg-white shadow-md md:hidden">
      <div className="container mx-auto max-w-screen-xl px-6 py-4 space-y-2">
        <a href="#benefits" className="block text-gray-800 hover:text-indigo-600" onClick={toggleMenu}>Benefits</a>
        <a href="#features" className="block text-gray-800 hover:text-indigo-600" onClick={toggleMenu}>Features</a>
        {/* <a href="#pricing" className="block text-gray-800 hover:text-indigo-600" onClick={toggleMenu}>Pricing</a> */}
        <a href="https://docs.fiscusflows.com/" target="_blank" rel="noopener noreferrer" className="block text-gray-800 hover:text-indigo-600" onClick={toggleMenu}>Developers</a>
        <a
          href="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
          className="block bg-indigo-600 text-white text-center px-4 py-2 rounded-full hover:bg-indigo-700 transition mt-4"
          onClick={toggleMenu}
        >
          Get Started
        </a>
      </div>
    </nav>
  );
};

export default MobileMenu;
