// src/components/EnhancedIntegrationChatSections.jsx

import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FiCode, FiMail, FiDatabase, FiCheckCircle, FiUser, FiMessageCircle } from 'react-icons/fi';

import docImage from '../assets/google-oauth-embed.png';

const scenarios = [
	{
	  title: "Guided Integration Setup",
	  subtitle: "Step-by-step assistance for seamless API setup, tailored for non-technical users. Start integrating tools like Gmail with ease, guided by intuitive, AI-powered instructions.",
	  messages: [
		{ user: true, text: "Can you help me set up authentication for Gmail?" },
		{ user: false, icon: <FiDatabase />, text: "Absolutely! Here’s the authentication documentation snippet you need to set up Gmail OAuth2." },
		{ user: false, docSnippet: true }, // This would display the OAuth documentation image snippet
		{ user: true, text: "Got it. What’s next?" }
	  ],
	},
	{
	  title: "Advanced Workflow Orchestration",
	  subtitle: "Effortlessly manage complex workflows with our SDK, designed for tech-savvy users. Automate processes like lead creation and custom logic for email verification, all in one flow.",
	  messages: [
		{ user: true, text: "I need a flow to create a lead in Salesforce and send a welcome email." },
		{ user: false, icon: <FiCode />, text: "Here's a sample flow using the Fiscus SDK:" },
		{
		  user: false,
		  codeSnippet: `
		  [
			{ 
			  "connector": "Salesforce",
			  "operation": "create_lead",
			  "params": { 
				"name": "Jane Doe", 
				"email": "jane.doe@example.com",
				"company": "Doe Corp"
			  } 
			},
			{ 
			  "connector": "Gmail",
			  "operation": "send_email",
			  "params": { 
				"to": "jane.doe@example.com", 
				"subject": "Welcome to Doe Corp!",
				"body": "Hello Jane, we're thrilled to have you on board!"
			  },
			  "custom_logic": "verify_email_sent"
			},
			{ 
			  "connector": "Salesforce",
			  "operation": "update_lead_status",
			  "params": { 
				"email": "jane.doe@example.com", 
				"status": "Welcome Email Sent"
			  },
			  "conditions": { 
				"if": "ctx.get('email_sent', False)" 
			  }
			}
		  ]
		`
		},
		{ user: true, text: "This looks good. I'll use that, thank you." }
	  ],
	},
	{
	  title: "AI-Driven Integration Plan",
	  subtitle: "Let AI craft a personalized integration strategy based on your unique needs. Sync data between platforms like Salesforce and HubSpot for real-time, reliable customer updates.",
	  messages: [
		{ user: true, text: "I want to sync my customer data across Salesforce and HubSpot." },
		{ user: false, icon: <FiMail />, text: "Got it! Here’s an integration plan to sync data between Salesforce and HubSpot for real-time updates." },
		{ user: false, icon: <FiCheckCircle />, text: "Connecting Salesforce with HubSpot. Mapping fields like Name, Email, and Company Status to keep customer information updated across both platforms." }
	  ],
	},
  ];
  

  const ChatSection = ({ scenario, isAlternate }) => {
	const [messages, setMessages] = useState([]);
	const [messageIndex, setMessageIndex] = useState(0);
	const [typing, setTyping] = useState(false);
	const [firstMessageAdded, setFirstMessageAdded] = useState(false);
	const containerRef = useRef(null);
  
	// Scroll to the latest message
	useEffect(() => {
	  if (containerRef.current) {
		containerRef.current.scrollTop = containerRef.current.scrollHeight;
	  }
	}, [messages, typing]);
  
	// Message handling with delays and typing indicator
	useEffect(() => {
	  const currentMessages = scenario.messages;
  
	  if (!firstMessageAdded && messageIndex === 0) {
		setMessages([currentMessages[0]]);
		setFirstMessageAdded(true);
		setMessageIndex(1);
		return;
	  }
  
	  if (messageIndex < currentMessages.length) {
		const message = currentMessages[messageIndex];
		if (!message.user) {
		  setTyping(true);
		  setTimeout(() => {
			setMessages((prev) => [...prev, message]);
			setTyping(false);
			setMessageIndex((prev) => prev + 1);
		  }, 2500);
		} else {
		  setTimeout(() => {
			setMessages((prev) => [...prev, message]);
			setMessageIndex((prev) => prev + 1);
		  }, 2000);
		}
	  } else {
		setTimeout(() => {
		  setMessages([currentMessages[0]]);
		  setMessageIndex(1);
		  setFirstMessageAdded(false);
		}, 5000);
	  }
	}, [messageIndex, scenario.messages, firstMessageAdded]);
  
	return (
	  <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between mb-16 lg:mb-24">
		{/* Title and Subtitle Section */}
		<div className={`w-full lg:w-1/2 mb-8 lg:mb-0 flex items-center ${isAlternate ? 'lg:order-2 lg:pl-16' : 'lg:order-1 lg:pr-16'} text-center lg:text-left`}>
		  <div>
			<h3 className="text-2xl lg:text-3xl font-semibold mb-2">{scenario.title}</h3>
			<p className="text-base lg:text-lg text-gray-600">{scenario.subtitle}</p>
		  </div>
		</div>
  
		{/* Chat Section */}
		<div
		  className={`w-full max-w-md h-80 p-4 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg flex flex-col lg:w-1/2 ${
			isAlternate ? 'lg:order-1 lg:mr-auto' : 'lg:order-2 lg:ml-auto'
		  }`}
		>
		  <div ref={containerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
			{messages.map((msg, index) => (
			  <motion.div
				key={index}
				initial={{ opacity: 0, y: 10 }}
				animate={{ opacity: 1, y: 0 }}
				className={`p-3 rounded-xl text-sm max-w-xs ${
				  msg.user ? 'bg-indigo-500 text-white ml-auto' : 'bg-gray-200 text-gray-800 mr-auto'
				} flex items-start space-x-2`}
			  >
				{msg.user ? (
				  <FiUser className="text-xl text-indigo-300" />
				) : (
				  <div className="text-xl text-indigo-300">{msg.icon}</div>
				)}
				<p>{msg.text}</p>
				{msg.docSnippet && (
				  <div className="mt-2">
					<img src={docImage} alt="Document Snippet" className="rounded-lg border shadow-sm" />
				  </div>
				)}
				{msg.codeSnippet && (
				  <pre className="text-xs bg-gray-800 text-green-400 rounded p-2 mt-2 overflow-hidden whitespace-pre-wrap">
					{msg.codeSnippet}
				  </pre>
				)}
			  </motion.div>
			))}
			{typing && (
			  <motion.div
				initial={{ opacity: 0, y: 10 }}
				animate={{ opacity: 1, y: 0 }}
				className="p-3 bg-gray-200 text-gray-800 rounded-xl text-sm max-w-xs mr-auto flex items-center space-x-2"
			  >
				<FiMessageCircle className="text-xl text-indigo-300" />
				<p>Typing...</p>
			  </motion.div>
			)}
		  </div>
		</div>
	  </div>
	);
  };
  
  // Main component with divider between sections on desktop
  const EnhancedIntegrationChatSections = () => {
	return (
	  <section id="features" className="bg-white py-20 relative">
		<div className="container mx-auto max-w-screen-lg px-6">
		  <h2 className="text-4xl font-bold text-center mb-12">AI-Powered Integration Lifecycle</h2>
		  {scenarios.map((scenario, index) => (
			<React.Fragment key={index}>
			  <ChatSection 
				scenario={scenario} 
				isAlternate={index % 2 !== 0} 
			  />
			  {index < scenarios.length - 1 && <div className="hidden lg:block border-t border-gray-300 my-8"></div>}
			</React.Fragment>
		  ))}
		</div>
	  </section>
	);
  };
  
  export default EnhancedIntegrationChatSections;
  