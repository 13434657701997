// src/components/HeroSection.jsx

import React, { Suspense, lazy } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const FiscusChat = lazy(() => import('./FiscusChat'));

const HeroSection = () => {
  return (
    <section className="bg-gray-50">
      <div className="container mx-auto max-w-screen-xl px-6 py-20 flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
        
        {/* Left Side: Text and CTA */}
        <div className="w-full lg:w-1/2">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">
            Meet Your <span className="text-indigo-600">AI Integration Engineer</span>
          </h1>
          <p className="text-lg text-gray-600 mb-8">
			<strong>Automate, integrate, and elevate</strong> with Fiscus—the only platform that empowers anyone to connect, control, and manage APIs with zero hassle. Imagine having complex workflows, authentication, and data management all handled by AI, so you can focus on growth, not code.
		  </p>

          <a
            href="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
            className="inline-flex items-center bg-indigo-600 text-white px-6 py-3 rounded-full hover:bg-indigo-700 transition text-lg"
          >
            Get Started <FaArrowRight className="ml-2" />
          </a>

          {/* Mobile Animation Preview */}
          <div className="mt-8 lg:hidden">
            <Suspense fallback={<div>Loading animation...</div>}>
              <FiscusChat />
            </Suspense>
            <div className="text-sm text-gray-500 text-center mt-4">
              See <span className="font-semibold text-indigo-600">Fiscus</span> automate workflows and power connections in action.
            </div>
          </div>
        </div>

        {/* Right Side: Integration Animation */}
        <div className="w-full lg:w-1/2 mt-10 lg:mt-0 flex flex-col items-center lg:items-end">
          <div className="hidden lg:block w-full max-w-md">
            <Suspense fallback={<div>Loading animation...</div>}>
              <FiscusChat />
            </Suspense>
            <div className="text-sm text-gray-500 text-center lg:text-left mt-4">
              Watch <span className="font-semibold text-indigo-600">Fiscus</span> streamline integration as your AI-powered engineer
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
