// src/components/Footer.jsx

import React from 'react';
import { FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto max-w-screen-xl px-6 py-10 flex flex-col md:flex-row justify-between items-center md:items-start space-y-8 md:space-y-0">
        
        {/* Brand and Tagline */}
        <div className="text-center md:text-left md:w-1/3 flex flex-col items-center md:items-start">
          <h3 className="text-xl font-semibold text-white mb-2">Fiscus</h3>
          <p className="text-sm text-gray-400 max-w-xs">
            Your AI Integration Engineer. Unlock seamless API orchestration and automation at every scale, powered by intelligent, conversational assistance.
          </p>
        </div>
        
        {/* Center-Aligned Quick Links */}
        <div className="md:w-1/3 flex flex-col items-center">
          <h4 className="text-lg font-semibold text-white mb-4">Explore</h4>
          <div className="flex flex-wrap justify-center space-x-6">
            <a href="#benefits" className="text-gray-400 hover:text-white transition">Benefits</a>
            <a href="#features" className="text-gray-400 hover:text-white transition">Features</a>
            {/* <a href="#pricing" className="text-gray-400 hover:text-white transition">Pricing</a> */}
            <a href="https://docs.fiscusflows.com/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">Developers</a>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex flex-col items-center md:items-end md:w-1/3">
          <h4 className="text-lg font-semibold text-white mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            <a href="https://www.linkedin.com/company/fiscus-flows/" aria-label="LinkedIn" className="hover:text-white transition">
              <FaLinkedin size={24} />
            </a>
            {/* Uncomment other social media links if needed */}
            {/* <a href="#" aria-label="Twitter" className="hover:text-white transition">
              <FaTwitter size={24} />
            </a>
            <a href="#" aria-label="Facebook" className="hover:text-white transition">
              <FaFacebook size={24} />
            </a> */}
          </div>
        </div>
      </div>

      {/* Bottom Bar with Extra Padding */}
      <div className="border-t border-gray-700 mt-8 pt-4 pb-4 text-center text-xs text-gray-500">
        &copy; {new Date().getFullYear()} Fiscus Flows, Inc. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
